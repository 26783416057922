
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { toggleClass } from '@/utils';
import '@/assets/custom-theme/index.css'; // the theme changed version element-ui css
import { useI18n } from 'vue-i18n';
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const dataMap = reactive({
      tags: [
        { name: 'Tag One', type: '' },
        { name: 'Tag Two', type: 'info' },
        { name: 'Tag Three', type: 'success' },
        { name: 'Tag Four', type: 'warning' },
        { name: 'Tag Five', type: 'danger' }
      ],
      theme: false,
      slideValue: 50,
      radio: 3
    });

    watch(() => dataMap.theme, () => {
      toggleClass(document.body, 'custom-theme');
    });

    return { t, ...toRefs(dataMap) };
  }
});
